import React from 'react'

export default function (props: Record<string, unknown>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      {...props}>
      <circle fill='#00c85f' cx='256' cy='256' r='256' />
      <path
        fill='#fff046'
        d='M251.41,135.209L65.354,248.46c-5.651,3.439-5.651,11.641,0,15.081L251.41,376.793  c2.819,1.716,6.36,1.716,9.18,0l186.057-113.251c5.651-3.439,5.651-11.641,0-15.081L260.59,135.209  C257.771,133.493,254.229,133.493,251.41,135.209z'
      />
      <circle fill='#4b82e1' cx='256' cy='256' r='70' />
      <path
        fill='#F5F5F5'
        d='M195.401,219.874c-3.332,5.578-5.905,11.64-7.605,18.077c39.149-2.946,97.062,8.006,133.922,43.773   c2.406-6.141,3.994-12.683,4.59-19.522C288.247,230.169,235.628,218.778,195.401,219.874z'
      />
    </svg>
  )
}
